import React from "react";
import { Link } from "gatsby";
import { Box, Section, Title, Text } from "../components/Core";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import PageWrapper from "../components/PageWrapper";
import bgHeroPattern from "../assets/image/webp/hero-pattern.webp";

const ContainerStyled = styled(Container)`
 height: 50vh;
`;

const SectionStyled = styled(Section)`
  &::before {
    position: absolute;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: url(${bgHeroPattern}) top center no-repeat;
    background-size: cover;
    z-index: -1;
  }
`;

const NotFoundPage = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <SectionStyled notfound>
          <ContainerStyled className="justify-content-center d-flex">
            <Row className="align-items-center px-2">
                <Box>
                  <Title className="my-4">
                    Oops! You weren't supposed to see this.
                  </Title>
                  <Text
                    variant="p">
                    This route does not exist. <br/> Return to the <Link to="/">home page</Link> and remember: you haven't seen anything.
                  </Text>
                </Box>
            </Row>
            </ContainerStyled>
        </SectionStyled>
      </PageWrapper>
    </>
  );
};
export default NotFoundPage;
